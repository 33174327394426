import {environment as defaultEnvironment} from './environment.default';

const keycloakConfig: any = {
    url: 'https://iam-uat.amis.com.mx/auth/',
    realm: 'AMIS',
    clientId: 'SCI_FRONT'
};

export const environment = {
    ...defaultEnvironment,
    sistema: 13, //Id del sistema SCI_FRONT generado por el gestor de accesos
    idvaluacion: 15, //Id del sistema SCI_VALUACION generado por el gestor de accesos
    seguridadEndpoint: 'https://api-uat.amis.com.mx/api/amis/facultades',
    //Esta url deberá ser un proxy con express que va a redirigir a la url interna en google cloud para GKE
    graphqlEndpoint: 'https://sci-uat.amis.com.mx/api/bitacora',
    keycloak: keycloakConfig,
    polizasVigentes: 'https://api-uat.amis.com.mx/api/amis/sci/1.0/polizas-vigentes/v1',
    estatusVehiculoOCRA: 'https://api-uat.amis.com.mx/api/amis/sci/1.0/ocra/v1',
    perdidaTotal: 'https://api-uat.amis.com.mx/api/amis/sci/1.0/perdida-total/v1',
    valuacion: 'https://api-uat.amis.com.mx/api/amis/sci/1.0/valuacion/v1'
};
